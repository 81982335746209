<template>
  <aside class="main-sidebar sidebar-light-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link logo-switch">
      <img src="@/assets/media/logos/logo.svg" class="brand-image-xl logo-xl" alt="">
      <img src="@/assets/media/logos/logomark.svg" class="brand-image-xs logo-xs" alt="">
    </a>

    <!-- Sidebar -->
    <div class="sidebar">

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link to="/files" class="nav-link" v-bind:class="{ 'active': this.$route.name === 'ListFiles' }">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Files
              </p>
            </router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link to="/create" class="nav-link" v-bind:class="{ 'active': $route.name === 'CreateFile' }">-->
<!--              <i class="nav-icon fas fa-plus"></i>-->
<!--              <p>-->
<!--                Create File-->
<!--              </p>-->
<!--            </router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link to="/users" class="nav-link" v-bind:class="{ 'active': $route.name === 'ListUsers' }">
              <i class="nav-icon fas fa-user"></i>
              <p>
                Users
              </p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "Navigation",
};
</script>

<style scoped>
ul.nav {
  padding-left: 0;
}
.active {
  background-color: #1976d2 !important;
}
</style>
