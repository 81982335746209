<template>
  <div class="versioning-wrapper" v-if="displayModal">
    <div class="modal-mask expand">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="header-right">
              <div id="close-modal" @click="closeModal">&#x2715;</div>
            </div>
          </div>
          <div class="slider">
            <div class="slider-items">
              <div
                class="slider-item"
                :style="featureStyles[index]"
                v-for="(feature, index) in version.features"
                :key="index"
                :set="(translation = feature.translations[language])"
              >
                <div class="modal-header">
                  <div class="header-left">
                    <div class="header-left-texts">
                      <div class="header-title">
                        <div>
                          <h4>{{ translation.title }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div>
                    <div class="presentation">
                      <video
                        v-if="
                          translation.media_mime &&
                          translation.media_mime === 'video/mp4'
                        "
                        controls
                      >
                        <source :src="translation.media_url" type="video/mp4" />
                      </video>
                      <img v-else :src="translation.media_url" />
                    </div>
                  </div>
                  <div class="bulletlist-wrapper">
                    <div v-html="translation.content"></div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="icon-arrow_back"
              v-if="version.features.length > 1"
              id="prev"
              @click="goPrev"
            >
              <svg
                height="50px"
                id="Layer_1"
                style="enable-background: new 0 0 50 50"
                version="1.1"
                viewBox="0 0 512 512"
                width="50px"
                color="#fff"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <polygon
                  points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 "
                />
              </svg>
            </span>
            <span
              class="icon-arrow_forward"
              v-if="version.features.length > 1"
              id="next"
              @click="goNext"
              style="transform: rotate(180deg)"
            >
              <svg
                height="50px"
                id="Layer_1"
                style="enable-background: new 0 0 50 50"
                version="1.1"
                viewBox="0 0 512 512"
                width="50px"
                color="#fff"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <polygon
                  points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 "
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VersioningModal",
  props: [
    "url",
    "versionFetchedCookieKey",
    "releaseVersionCookieKey",
    "language",
  ],
  data() {
    return {
      version: null,
      displayModal: false,
      loop: 0,
      featureStyles: [],
    };
  },
  mounted() {
    this.fetchVersioning();
  },
  methods: {
    fetchVersioning() {
      let versioningFetchedCookieKey = this.versionFetchedCookieKey;
      let versioningReleaseCookieKey = this.releaseVersionCookieKey;

      if (!this.getCookie(versioningFetchedCookieKey)) {
        let versionFetchedCookieExpireAt = new Date();
        versionFetchedCookieExpireAt.setHours(23, 59, 59, 0);

        axios.get(this.url).then((respone) => {
          this.version = respone.data.data;

          if (
            this.version.version &&
            this.getCookie(versioningReleaseCookieKey) !== this.version.version
          ) {
            this.displayVersionModal();

            let releaseVersionCookieExpireAt = new Date(
              versionFetchedCookieExpireAt.getFullYear() + 100,
              0
            );

            this.setCookie(
              versioningReleaseCookieKey,
              this.version.version,
              releaseVersionCookieExpireAt
            );
          }
        });

        this.setCookie(
          versioningFetchedCookieKey,
          true,
          versionFetchedCookieExpireAt
        );
      }
    },

    displayVersionModal() {
      this.loop = 1000 * this.version.features.length;

      let featureLength = this.version.features.length;

      for (let index = 0; index < featureLength; index++) {
        let style = {};
        const translateX = 100 * index;

        style.transform = "translateX(" + translateX + "%)";

        if (translateX === 0) {
          style.position = "relative";
        } else {
          style.position = null;
        }

        this.featureStyles.push(style);
      }

      this.displayModal = true;
    },

    goNext() {
      this.loop++;

      let featureLength = this.version.features.length;
      let featureStyles = [];

      for (let index = 0; index < featureLength; index++) {
        let style = {};

        const translateX = 100 * (index - (this.loop % featureLength));
        style.transform = "translateX(" + translateX + "%)";

        if (translateX === 0) {
          style.position = "relative";
        } else {
          style.position = null;
        }

        featureStyles.push(style);
      }

      this.featureStyles = featureStyles;
    },

    goPrev() {
      this.loop--;

      let featureLength = this.version.features.length;
      let featureStyles = [];

      for (let index = 0; index < featureLength; index++) {
        let style = {};

        const translateX = 100 * (index - (this.loop % featureLength));
        style.transform = "translateX(" + translateX + "%)";

        if (translateX === 0) {
          style.position = "relative";
        } else {
          style.position = null;
        }

        featureStyles.push(style);
      }

      this.featureStyles = featureStyles;
    },

    setCookie(name, value, expireAt) {
      var expires = "";
      if (expireAt) {
        expires = "; expires=" + expireAt.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    closeModal() {
      this.displayModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.versioning-wrapper {
  .modal-mask {
    left: auto;
    right: 0;
    height: 100%;
    min-width: calc(100% - 19.2rem) !important;
    width: 100%;
    top: 0;
    z-index: 10;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);

    &.expand {
      transition: 0.4s;
    }
  }

  .modal-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-container {
    width: 470px;
    max-height: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    height: auto;
    overflow: hidden;
    position: relative;
  }

  #close-modal {
    font-size: 17px;
    cursor: pointer;
    margin: 10px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: none;
    padding-bottom: 0;

    .header-right {
      margin-left: auto;
    }
  }

  .slider {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    min-height: 400px;

    .slider-items {
      height: 100%;
      font-size: inherit;
      text-transform: uppercase;

      .slider-item {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 1s;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    .modal-header {
      border-bottom: 1px solid #dee2e6;
      padding: 1rem 1rem;

      .header-left {
        width: 100%;

        h4 {
          text-align: center;
        }
      }
    }

    .presentation {
      margin: 15px;

      img {
        align-self: center;
        margin: auto;
        width: auto;
        height: auto;
        display: block;
        max-width: 100%;
        user-select: none;
      }

      video {
        width: 100%;
        height: auto;
      }
    }

    .modal-body {
      height: 70%;
      width: 100%;
      margin: 30px 0;
      padding: 0 30px;

      .bulletlist-wrapper {
        line-height: 2em;
        margin-left: 3rem;
        margin-right: 3rem;

        ul,
        ol,
        li {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        li {
          display: list-item;
          list-style: disc;
        }
      }
    }

    .modal-footer {
      padding: 15px 30px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  #prev,
  #next {
    position: absolute;
    top: calc(50% - 59px);
    cursor: pointer;
  }

  #prev {
    left: 0;
    border-radius: 0 10px 10px 0;
  }

  #next {
    right: 0;
    border-radius: 10px 0 0 10px;
  }

  #prev svg,
  #next svg {
    width: 100%;
    fill: #000000;
  }
}
</style>
