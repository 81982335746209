<template>
  <div class="content-wrapper px-4 py-2">
    <router-view></router-view>
    <VersioningModal
      :url="VUE_APP_VERSIONING_URL"
      :release-version-cookie-key="VUE_RELEASE_VERSIONING_COOKIE_KEY"
      :version-fetched-cookie-key="VUE_FETCHED_VERSIONING_COOKIE_KEY"
      language="en"
    ></VersioningModal>
  </div>
</template>

<script>
import VersioningModal from "@/components/modals/VersioningModal";
import {
  VUE_APP_VERSIONING_URL,
  VUE_RELEASE_VERSIONING_COOKIE_KEY,
  VUE_FETCHED_VERSIONING_COOKIE_KEY,
} from "@/constants";

export default {
  name: "Content",
  data() {
    return {
      VUE_APP_VERSIONING_URL,
      VUE_RELEASE_VERSIONING_COOKIE_KEY,
      VUE_FETCHED_VERSIONING_COOKIE_KEY,
    };
  },
  components: {
    VersioningModal,
  },
};
</script>

<style scoped></style>
